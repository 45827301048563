<template>
  <NuxtLink
    class="text-center"
    :to="link"
    v-bind="
      downloadLinkActive && true
        ? {
            external: true,
            download: magazine.Name,
            target: '_blank',
          }
        : {}
    "
  >
    <div class="rounded-md overflow-hidden relative">
      <NuxtImg
        v-if="magazine.ImageUrl"
        :src="magazine.ImageUrl"
        :width="370"
        :height="500"
        class="max-w-56 md:max-w-64"
      />
    </div>
    <div
      class="mt-3"
      :class="true ? 'label-m' : 'list-title-s !font-medium'"
    >
      {{ magazine.Name }}
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import type { Magazine } from '~/api/content'

const userStore = useUserStore()
const props = defineProps<{ magazine: Magazine }>()

const downloadLinkActive = computed(() => {
  return userStore.hasSubscription(1)
})

const link = computed(() => {
  if (true) {
    if (downloadLinkActive.value) {
      if (
        userStore.hasLoginType('IP', 'Wayf') &&
        userStore.user?.legacyAutoToken // allow none jwt migrated users to user legacy token
      ) {
        return `${new URL(props.magazine.Link).pathname}?token=${encodeURI(
          userStore.user?.legacyAutoToken ?? ''
        )}`
      }
      return new URL(props.magazine.Link).pathname
    } else {
      return { name: 'login' }
    }
  } else {
    return {
      name: 'magasin_vis',
      params: {
        id: props.magazine.RecordId,
      },
    }
  }
})
</script>
